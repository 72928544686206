
.section2-container {
    text-align: center;
    padding: 50px 0;
    background-color: #2e2e2e; 
    
}

#title-game {
    color: white;
}
.section2-title {
    font-size: 2.5rem;
    margin-bottom: 30px;
    color: #fff;
    text-transform: uppercase;
    animation: fadeInTitle 1s ease-in-out;
}

.carousel-wrapper {
    position: relative;
    width: 70%;
    height: 420px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.carousel {
    display: flex;
    transition: transform 0.8s ease-in-out; 
    justify-content: space-between;
}

.game-card {
    flex: 0 0 30%;
    margin: 0 15px;
    position: relative;
    border-radius: 10px;
    overflow: hidden;
    height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #1a1a1a;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.game-card:hover {
    transform: scale(1.05);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}

.game-image {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: 0.2s;
}

.game-title {
    position: absolute;
    bottom: 50%;
    left: 0;
    right: 0;
    font-size: 1.5rem;
    color: white;
    text-align: center;
    background-color: transparent;
    transition: bottom 0.3s ease, background-color 0.3s ease;
    z-index: 2;
    padding: 10px 0;
}

.game-card:hover .game-title {
    bottom: 60px; 
    background-color: rgba(255, 140, 0, 0.8); 
    transition: bottom 0.5s ease-in-out, background-color 0.5s ease-in-out; 
}

.game-card:hover::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 140, 0, 0.5); 
    z-index: 1; 
}

.carousel-button {
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.7);
    border: none;
    color: white;
    font-size: 2rem;
    padding: 10px;
    cursor: pointer;
    border-radius: 50%;
    z-index: 10; 
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.carousel-button.left {
    left: -20px;
}

.carousel-button.right {
    right: -20px;
}

.carousel-button:hover {
    background-color: rgba(255, 140, 0, 0.8);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.fade-in {
    opacity: 0;
    animation: fadeIn 0.5s forwards ease-in;
}

.fade-out {
    opacity: 1;
    animation: fadeOut 0.5s forwards ease-in;
}
@keyframes fadeInTitle {
    0% {
        opacity: 0;
        transform: translateY(-20px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
        transform: scale(0.95);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1;
        transform: scale(1);
    }
    100% {
        opacity: 0;
        transform: scale(0.95);
    }
}

@media screen and (max-width: 900px) {
    .carousel {
        width: 100%;
    }

    .game-card {
        flex: 0 0 45%;
        height: 350px;
    }
}

@media screen and (max-width: 600px) {
    .carousel-button {
        font-size: 1.5rem;
    }

    .game-card {
        flex: 0 0 80%;
        height: 300px;
    }
}
