.mini-map-container {
    position: relative;
    width: 100%;
    max-width: 800px;
    margin: 20px auto;
  }
  
  .mini-map-image {
    width: 100%;
    height: auto;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .map-point {
    position: absolute;
    width: 20px;
    height: 20px;
    background-color: rgba(255, 69, 0, 0.8);
    border: 2px solid #fff;
    border-radius: 50%;
    cursor: pointer;
    transition: transform 0.3s ease;
  }
  
  .map-point:hover {
    transform: scale(1.2);
  }
  
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    max-width: 400px;
  }
  
  .modal-content h2 {
    margin-bottom: 10px;
    color: #ff4500;
  }
  
  .modal-content p {
    margin-bottom: 20px;
  }
  
  .modal-content button {
    background-color: #ff4500;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .modal-content button:hover {
    background-color: #ff6347;
  }
  