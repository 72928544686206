
@font-face {
  font-family: 'WarFont';
  src: url('./police/CAMPUS PERSONAL USE.ttf'); 
}
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

body {
  overflow-x: hidden;
  background-color: #2e2e2e; 
  font-family: 'Montserrat';
}

.transition-bar {
  width: 100%;
  height: 50px;
  background: linear-gradient(to bottom, rgba(255, 69, 0, 0.8), rgba(46, 46, 46, 0.9));
  margin-bottom: 20px;
}

.transition-text {
  text-align: center;
  color: #ff4500;
  font-size: 2.5rem;
  margin: 30px 0;
  font-weight: bold;
}

.txtbf {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
}

.home-container {
  position: relative;
}

.image-overlay {
  position: relative;
  width: 100%;
  background: rgba(255, 69, 0, 0.8);
}

.text-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
  font-family: WarFont;
}

.text-container h1 {
  font-size: 2.5vw;
  margin-bottom: 10px;
}

.text-container p {
  font-size: 1.5vw;
  margin-bottom: 20px;
}

.text-container a {
  font-size: 1.2vw;
  color: white;
  text-decoration: none;
  border: 1px solid white;
  padding: 10px 20px;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.text-container a:hover {
  background-color: white;
  color: black;
}


@media screen and (max-width: 1500px) {
  .text-container {
    width: 90%;
  }
}

 .gray-section {
 
    height: 30vh;
    display: flex;
    align-items: center;

}

.gray-section h2 {
  font-size: 1.5em;
  color: #333;
}

.gray-section p {
  font-size: 1.2em;
  color: #555;
}

.info-description-simple-container {
  display: flex;
  align-items: center;
  justify-content: center;
  
  
}

.plc-img-base {
  background: url('./../asset/Base England.png') center/cover no-repeat; 
    width: 100vw;
    display: flex;
    align-items: center;
    height: 90vh;
}
.overlay-rectangle-mobilisation {
  background-color: rgba(192, 168, 144, 0.8); 
  border-radius: 15px;
  padding: 20px;
  text-align: center;
  color: #000000;
  width: 60vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}


.txt-mobilisation {
  font-size: 2vw;
  font-weight : bold ;
} 

.title-Section3 {
  display: flex;
  flex-direction: column;
  align-items:  center;
  color : white ;
  font-size: 3vw;
  margin-bottom: 5vh;
  margin-top: 5vh;
}
.sous-title-Section3{
  display: flex;
    color: white;
    font-size: 1vw;
    width: 40vw;
    justify-content: center;
    height: 10vh;
}

.food-section-container {
  text-align: center;
  padding: 50px 0;
  background-color: #2e2e2e; /* Fond sombre similaire au reste du site */
}

.food-section-title {
  font-size: 2.5rem;
  margin-bottom: 40px;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 1px;
  animation: fadeInTitle 1s ease-in-out; /* Animation douce pour le titre */
  display: flex;
  justify-content: center;
}

/* Animation pour l'apparition du titre */
@keyframes fadeInTitle {
  0% {
      opacity: 0;
      transform: translateY(-20px);
  }
  100% {
      opacity: 1;
      transform: translateY(0);
  }
}

