
* {
  margin: 0;
  padding: 0;
  text-decoration: none;
  list-style: none;
}
html {
  scroll-behavior: smooth;
}


body {
  font-family: Arial, Helvetica, sans-serif;
}

.navbar {
  position: fixed;
  padding: 2vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  z-index: 10;
  transition: transform 0.3s ease, background-color 0.3s ease; 
}

.navbar.scrolled {
  background-color: rgba(35, 35, 35, 0.9); 
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3); 
}

.navbar.hidden {
  transform: translateY(-100%); 
}

.navbar a {
  color: rgb(255, 255, 255);
  transition: color 0.3s ease; 
}

.navbar a:hover {
  color: red; 
}

.navbar .logo-title {
  width: 10vw;
  transition: transform 0.3s ease;
}

.navbar .nav-links ul {
  display: flex;
}

.navbar .nav-links ul li {
  margin: 0 25px;
  font-size: 1vw;
}

.navbar .nav-links ul li.active a {
  color: red;
  font-weight: 700;
}

.navbar .menu-burger {
  display: none;
  width: 5vw;
  position: fixed;
  top: 50px;
  right: 50px;
  cursor: pointer;
  transition: transform 0.3s ease; 
}

.navbar .menu-burger:hover {
  transform: rotate(90deg);
}

/* Mobile Menu */
@media screen and (max-width: 900px) {
  .navbar {
    padding: 0;
  }

  .navbar .logo-title {
    position: absolute;
    top: 50px;
    left: 50px;
    width: 20vw;
  }

  .nav-links {
    position: absolute;
    width: 100%;
    height: 100vh;
    background-color: rgba(35, 35, 35, 0.95); 
    backdrop-filter: blur(10px);
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: -100%;
    transition: all 0.5s ease;
  }

  .nav-links.mobile-menu {
    margin-left: 0;
  }

  .nav-links ul {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .navbar .menu-burger {
    display: block;
  }

  .navbar .nav-links ul li {
    margin: 25px 0;
    font-size: 1.2rem;
  }
}
