.section-bf3-container {
    width: 100%;
    background-color: #2e2e2e;
    padding: 50px 0;
    display: flex;
    justify-content: center;
}

.section-bf3-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    max-width: 1200px;
    width: 90%;
}

.section-bf3-image img {
    width: 100%;
    max-width: 550px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
}

.section-bf3-text {
    color: #fff;
    padding-left: 30px;
    max-width: 600px;
}

.section-bf3-text h2 {
    font-size: 2rem;
    color: #ff4500;
    margin-bottom: 20px;
}

.section-bf3-text p {
    font-size: 1.1rem;
    line-height: 1.6;
    color: #bdbdbd;
}

.section-bf1-container {
    width: 100%;
    background-color: #2e2e2e;
    padding: 50px 0;
    display: flex;
    justify-content: center;
}

.section-bf1-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    max-width: 1200px;
    width: 90%;
}

.section-bf1-image img {
    width: 100%;
    max-width: 550px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
}

.section-bf1-text {
    color: #fff;
    padding-right: 30px;
    max-width: 600px;
    text-align: left;
}

.section-bf1-text h2 {
    font-size: 2rem;
    color: #ff4500;
    margin-bottom: 20px;
}

.section-bf1-text p {
    font-size: 1.1rem;
    line-height: 1.6;
    color: #bdbdbd;
}

@media screen and (max-width: 900px) {
    .section-bf3-content, .section-bf1-content {
        flex-direction: column;
    }

    .section-bf3-text, .section-bf1-text {
        padding: 0;
        text-align: center;
        margin-top: 20px;
    }

    .section-bf3-image img, .section-bf1-image img {
        max-width: 100%;
    }
}

@keyframes fadeInUp {
    0% {
        opacity: 0;
        transform: translateY(30px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.section-bf3-container,
.section-bf1-container {
    opacity: 0;
    transform: translateY(30px);
    transition: opacity 0.6s ease-out, transform 0.6s ease-out;
    will-change: opacity, transform;
}

.section-bf3-container.visible,
.section-bf1-container.visible {
    opacity: 1;
    transform: translateY(0);
}

.section-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    max-width: 1200px;
    width: 90%;
}

.section-bf3-image img,
.section-bf1-image img {
    width: 100%;
    max-width: 550px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
}

.section-bf3-text,
.section-bf1-text {
    color: #fff;
    max-width: 600px;
}

.section-bf3-text h2,
.section-bf1-text h2 {
    font-size: 2rem;
    color: #ff4500;
    margin-bottom: 20px;
}

.section-bf3-text p,
.section-bf1-text p {
    font-size: 1.1rem;
    line-height: 1.6;
    color: #bdbdbd;
}

@media screen and (max-width: 900px) {
    .section-content {
        flex-direction: column;
    }

    .section-bf3-text,
    .section-bf1-text {
        padding: 0;
        text-align: center;
        margin-top: 20px;
    }

    .section-bf3-image img,
    .section-bf1-image img {
        max-width: 100%;
    }
}
