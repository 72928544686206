.compteurs-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 50px;
  padding: 20px;
  width: 90%;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  min-height: 210px;
}


.compteur-item {
  display: flex;
  flex-direction: column;
  align-items: center;
}


.circle {
  width: 180px;
  height: 180px;
  background-color: transparent;
  border-radius: 50%;
  position: relative;
  background: conic-gradient(#ff4500 0%, #ff4500 70%, #e0e0e0 70%);
  box-shadow: 0 4px 15px rgba(255, 69, 0, 0.4), 
              inset 0px 0px 20px rgba(255, 255, 255, 0.1); 
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 0.3s ease; 
}

.circle:hover {
  transform: scale(1.1); 
  box-shadow: 0 6px 25px rgba(255, 69, 0, 0.6); 
}

.circle-content {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
}

.circle-content h3 {
  font-size: 1.3rem;
  margin: 0;
  color: #fff;
}

.circle-content p {
  font-size: 2rem;
  margin: 0;
  font-weight: bold;
  color: #fff;
}

.circle::before {
  content: '';
  width: 100%;
  height: 100%;
  background-color: transparent;
  border-radius: 50%;
  position: absolute;
  border: 10px solid #2e2e2e;
  animation: fill-circle 3s ease forwards;
  background: conic-gradient(#ff4500 0%, #ff8c00 50%, #ffd700 100%); /* Dégradé fluide */
}

@keyframes fill-circle {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.circle::after {
  content: '';
  width: 100%;
  height: 100%;
  border-radius: 50%;
  position: absolute;
  animation: pulse 2s infinite ease-in-out;
}

@keyframes pulse {
  0%, 100% {
    box-shadow: 0 0 10px rgba(255, 69, 0, 0.3);
  }
  50% {
    box-shadow: 0 0 20px rgba(255, 69, 0, 0.6);
  }
}

@media screen and (max-width: 900px) {
  .compteurs-container {
    flex-direction: column;
  }

  .circle {
    width: 140px;
    height: 140px;
  }

  .circle-content h3 {
    font-size: 1.1rem;
  }

  .circle-content p {
    font-size: 1.6rem;
  }
}
