.motivational-header {
    font-size: 2.5rem; 
    font-weight: bold; 
    text-align: center; 
    margin-bottom: 20px; 
    color: #fff; 
  }

.motivational-section {
    background-color: #2e2e2e; 
    padding: 50px 0;
    text-align: center;
    display: flex; 
    justify-content: center;
    align-items: flex-start;
}

.motivational-container {
    display: flex;
    max-width: 1200px; 
    width: 100%;
    justify-content: space-between;
    align-items: flex-start;
    
    padding: 0 20px; 
}

.map-section {
    flex: 1; 
    margin-right: 50px; 
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.motivational-content {
    flex: 2; 
    max-width: 800px;
    margin: 0;
    padding: 20px;
    background: rgba(0, 0, 0, 0.6); 
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    text-align: left; 
}

.motivational-content p {
    font-size: 1.2rem;
    line-height: 1.6;
    margin-bottom: 20px;
}

.motivational-content .last-paragraph {
    font-size: 1.4rem;
    color: #ffa500; 
}

.motivational-content {
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.8s ease, transform 0.8s ease;
}

.motivational-section.in-view .motivational-content {
    opacity: 1;
    transform: translateY(0);
    text-align: center;
    color: aliceblue;
}

@media screen and (max-width: 768px) {
    .motivational-container {
        flex-direction: column; 
        align-items: center;
    }

    .map-section {
        margin-right: 0;
        margin-bottom: 30px; 
        align-items: center; 
    }

    .motivational-content {
        padding: 15px;
    }

    .motivational-content p {
        font-size: 1rem;
    }
}
