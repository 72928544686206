.formulaire-inscription-container {
    padding: 60px 40px;
    text-align: center;
    color: white;
    border-radius: 10px;
    max-width: 800px;
    margin: 0 auto;
  }
  
  .formulaire-inscription-container h2 {
    font-size: 2.5rem;
    color: #ff4500;
    margin-bottom: 20px;
    text-transform: uppercase;
    letter-spacing: 1.5px;
  }
  
  .formulaire-inscription-container p {
    font-size: 1.2rem;
    color: #bdbdbd;
    margin-bottom: 40px;
    line-height: 1.6;
  }
  
  .form-group {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .input-large {
    width: 70%;
    padding: 15px;
    margin-bottom: 30px;
    border: none;
    border-radius: 5px;
    font-size: 1.1rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .input-small {
    width: 45%;
    padding: 15px;
    margin-bottom: 20px;
    margin-right: 10px;
    border: none;
    border-radius: 5px;
    font-size: 1.1rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .input-small:last-child {
    margin-right: 0;
  }
  
  .submit-button {
    background-color: #ff4500;
    color: white;
    padding: 15px 30px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1.2rem;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-top: 30px;
  }
  
  .submit-button:hover {
    background-color: #e03e00;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
  }
  
  .success-message {
    margin-top: 40px;
    color: #bdbdbd;
  }
  
  .success-message p {
    font-size: 1.3rem;
  }
  
  .success-message a {
    display: inline-block;
    background-color: #7289da;
    color: white;
    padding: 15px 30px;
    border-radius: 5px;
    text-decoration: none;
    margin-top: 15px;
  }
  
  .success-message a:hover {
    background-color: #5a6fb8;
  }
  
  @media screen and (max-width: 768px) {
    .input-large {
      width: 90%;
    }
  
    .form-group {
      flex-direction: column;
    }
  
    .input-small {
      width: 90%;
      margin-right: 0;
    }
  }
  