@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


* {
  margin: 0;
  padding: 0;
  text-decoration: none;
  list-style: none;
}
html {
  scroll-behavior: smooth;
}


body {
  font-family: Arial, Helvetica, sans-serif;
}

.navbar {
  position: fixed;
  padding: 2vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  z-index: 10;
  transition: transform 0.3s ease, background-color 0.3s ease; 
}

.navbar.scrolled {
  background-color: rgba(35, 35, 35, 0.9); 
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3); 
}

.navbar.hidden {
  transform: translateY(-100%); 
}

.navbar a {
  color: rgb(255, 255, 255);
  transition: color 0.3s ease; 
}

.navbar a:hover {
  color: red; 
}

.navbar .logo-title {
  width: 10vw;
  transition: transform 0.3s ease;
}

.navbar .nav-links ul {
  display: flex;
}

.navbar .nav-links ul li {
  margin: 0 25px;
  font-size: 1vw;
}

.navbar .nav-links ul li.active a {
  color: red;
  font-weight: 700;
}

.navbar .menu-burger {
  display: none;
  width: 5vw;
  position: fixed;
  top: 50px;
  right: 50px;
  cursor: pointer;
  transition: transform 0.3s ease; 
}

.navbar .menu-burger:hover {
  transform: rotate(90deg);
}

/* Mobile Menu */
@media screen and (max-width: 900px) {
  .navbar {
    padding: 0;
  }

  .navbar .logo-title {
    position: absolute;
    top: 50px;
    left: 50px;
    width: 20vw;
  }

  .nav-links {
    position: absolute;
    width: 100%;
    height: 100vh;
    background-color: rgba(35, 35, 35, 0.95); 
    -webkit-backdrop-filter: blur(10px); 
            backdrop-filter: blur(10px);
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: -100%;
    transition: all 0.5s ease;
  }

  .nav-links.mobile-menu {
    margin-left: 0;
  }

  .nav-links ul {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .navbar .menu-burger {
    display: block;
  }

  .navbar .nav-links ul li {
    margin: 25px 0;
    font-size: 1.2rem;
  }
}

footer {
  background-color: #1a1a1a;
  color: white;
  padding: 50px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  transition: background-color 0.3s ease-in-out;
}

footer:hover {
  background-color: #222222; 
}

.footer-container1 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 80vw;
  transition: transform 0.3s ease;
}

/* .footer-container1:hover {
  transform: translateY(-10px); 
} */

.footer-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 80%;
  grid-gap: 10px;
  gap: 10px;
  flex-wrap: wrap;
}

.footer-logo-image {
  width: 100px;
  height: auto;
  margin-right: 20px;
  margin-left: 10vw;
  transition: opacity 0.5s ease, transform 0.3s ease-in-out;
}

@media (max-width: 768px) {
  .footer-logo-image {
    opacity: 0;
    transform: scale(0.8);
    pointer-events: none; 
  }
}

.footer-about,
.footer-categories,
.footer-links {
  flex: 1 1;
  margin-bottom: 30px;
  transition: opacity 0.5s ease, transform 0.3s ease;
}

.footer-about,
.footer-categories,
.footer-links {
  opacity: 0;
  transform: translateY(20px);
  animation: fadeInUp 1s forwards;
}

.footer-about {
  animation-delay: 0.1s;
}

.footer-categories {
  animation-delay: 0.3s;
}

.footer-links {
  animation-delay: 0.5s;
}

@keyframes fadeInUp {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.footer-about h3,
.footer-categories h3,
.footer-links h3 {
  font-size: 1.2em;
  margin-bottom: 15px;
  color: #ff4500;
  transition: color 0.3s ease;
}

.footer-about h3:hover,
.footer-categories h3:hover,
.footer-links h3:hover {
  color: #ffa500;
}

.footer-about p,
.footer-categories ul,
.footer-links ul {
  font-size: 0.9em;
  line-height: 1.6;
  color: rgba(255, 255, 255, 0.8);
}

.footer-categories ul,
.footer-links ul {
  list-style: none;
  padding: 0;
}

.footer-categories ul li,
.footer-links ul li {
  margin-bottom: 10px;
}

.footer-links ul li a {
  color: rgba(255, 255, 255, 0.8);
  text-decoration: none;
  transition: color 0.3s ease;
}

.footer-links ul li a:hover {
  color: #ff4500;
}

hr {
  border: none;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  width: 80%;
  margin: 30px auto;
}

.footer-copyright {
  text-align: center;
  font-size: 0.8em;
  color: rgba(255, 255, 255, 0.7);
  width: 80%;
  margin: 0 auto;
  max-width: 1200px;
}

@media (max-width: 768px) {
  .footer-container {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  hr {
    width: 90%;
  }

  .footer-copyright {
    font-size: 0.7em;
  }
}


@font-face {
  font-family: 'WarFont';
  src: url("/static/media/CAMPUS PERSONAL USE.8eba57ce.ttf"); 
}

body {
  overflow-x: hidden;
  background-color: #2e2e2e; 
  font-family: 'Montserrat';
}

.transition-bar {
  width: 100%;
  height: 50px;
  background: linear-gradient(to bottom, rgba(255, 69, 0, 0.8), rgba(46, 46, 46, 0.9));
  margin-bottom: 20px;
}

.transition-text {
  text-align: center;
  color: #ff4500;
  font-size: 2.5rem;
  margin: 30px 0;
  font-weight: bold;
}

.txtbf {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
}

.home-container {
  position: relative;
}

.image-overlay {
  position: relative;
  width: 100%;
  background: rgba(255, 69, 0, 0.8);
}

.text-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
  font-family: WarFont;
}

.text-container h1 {
  font-size: 2.5vw;
  margin-bottom: 10px;
}

.text-container p {
  font-size: 1.5vw;
  margin-bottom: 20px;
}

.text-container a {
  font-size: 1.2vw;
  color: white;
  text-decoration: none;
  border: 1px solid white;
  padding: 10px 20px;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.text-container a:hover {
  background-color: white;
  color: black;
}


@media screen and (max-width: 1500px) {
  .text-container {
    width: 90%;
  }
}

 .gray-section {
 
    height: 30vh;
    display: flex;
    align-items: center;

}

.gray-section h2 {
  font-size: 1.5em;
  color: #333;
}

.gray-section p {
  font-size: 1.2em;
  color: #555;
}

.info-description-simple-container {
  display: flex;
  align-items: center;
  justify-content: center;
  
  
}

.plc-img-base {
  background: url("/static/media/Base England.34fba866.png") center/cover no-repeat; 
    width: 100vw;
    display: flex;
    align-items: center;
    height: 90vh;
}
.overlay-rectangle-mobilisation {
  background-color: rgba(192, 168, 144, 0.8); 
  border-radius: 15px;
  padding: 20px;
  text-align: center;
  color: #000000;
  width: 60vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}


.txt-mobilisation {
  font-size: 2vw;
  font-weight : bold ;
} 

.title-Section3 {
  display: flex;
  flex-direction: column;
  align-items:  center;
  color : white ;
  font-size: 3vw;
  margin-bottom: 5vh;
  margin-top: 5vh;
}
.sous-title-Section3{
  display: flex;
    color: white;
    font-size: 1vw;
    width: 40vw;
    justify-content: center;
    height: 10vh;
}

.food-section-container {
  text-align: center;
  padding: 50px 0;
  background-color: #2e2e2e; /* Fond sombre similaire au reste du site */
}

.food-section-title {
  font-size: 2.5rem;
  margin-bottom: 40px;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 1px;
  animation: fadeInTitle 1s ease-in-out; /* Animation douce pour le titre */
  display: flex;
  justify-content: center;
}

/* Animation pour l'apparition du titre */
@keyframes fadeInTitle {
  0% {
      opacity: 0;
      transform: translateY(-20px);
  }
  100% {
      opacity: 1;
      transform: translateY(0);
  }
}



 @font-face {
     font-family: 'TimerFont';
     src: url("/static/media/28 Days Later.60cb8409.ttf"); 
 }
  
  .timer {
    display: flex;
    justify-content: center;
    font-size: 1vw;
  }
  
  .time-unit {
    margin: 0 20px;
    text-align: center;
  }
  
  .value {
    font-size: 1.5vw;
    font-family: TimerFont;
  }
  
  .label {
    font-size: 1.2em;
    color: white;
    font-family: TimerFont;
  }

#letter-timer {
    font-size: 1.6vw;
    margin-bottom: 10px;
  }
  

.section2-container {
    text-align: center;
    padding: 50px 0;
    background-color: #2e2e2e; 
    
}

#title-game {
    color: white;
}
.section2-title {
    font-size: 2.5rem;
    margin-bottom: 30px;
    color: #fff;
    text-transform: uppercase;
    animation: fadeInTitle 1s ease-in-out;
}

.carousel-wrapper {
    position: relative;
    width: 70%;
    height: 420px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.carousel {
    display: flex;
    transition: transform 0.8s ease-in-out; 
    justify-content: space-between;
}

.game-card {
    flex: 0 0 30%;
    margin: 0 15px;
    position: relative;
    border-radius: 10px;
    overflow: hidden;
    height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #1a1a1a;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.game-card:hover {
    transform: scale(1.05);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}

.game-image {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: 0.2s;
}

.game-title {
    position: absolute;
    bottom: 50%;
    left: 0;
    right: 0;
    font-size: 1.5rem;
    color: white;
    text-align: center;
    background-color: transparent;
    transition: bottom 0.3s ease, background-color 0.3s ease;
    z-index: 2;
    padding: 10px 0;
}

.game-card:hover .game-title {
    bottom: 60px; 
    background-color: rgba(255, 140, 0, 0.8); 
    transition: bottom 0.5s ease-in-out, background-color 0.5s ease-in-out; 
}

.game-card:hover::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 140, 0, 0.5); 
    z-index: 1; 
}

.carousel-button {
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.7);
    border: none;
    color: white;
    font-size: 2rem;
    padding: 10px;
    cursor: pointer;
    border-radius: 50%;
    z-index: 10; 
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.carousel-button.left {
    left: -20px;
}

.carousel-button.right {
    right: -20px;
}

.carousel-button:hover {
    background-color: rgba(255, 140, 0, 0.8);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.fade-in {
    opacity: 0;
    animation: fadeIn 0.5s forwards ease-in;
}

.fade-out {
    opacity: 1;
    animation: fadeOut 0.5s forwards ease-in;
}
@keyframes fadeInTitle {
    0% {
        opacity: 0;
        transform: translateY(-20px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
        transform: scale(0.95);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1;
        transform: scale(1);
    }
    100% {
        opacity: 0;
        transform: scale(0.95);
    }
}

@media screen and (max-width: 900px) {
    .carousel {
        width: 100%;
    }

    .game-card {
        flex: 0 0 45%;
        height: 350px;
    }
}

@media screen and (max-width: 600px) {
    .carousel-button {
        font-size: 1.5rem;
    }

    .game-card {
        flex: 0 0 80%;
        height: 300px;
    }
}

.section-bf3-container {
    width: 100%;
    background-color: #2e2e2e;
    padding: 50px 0;
    display: flex;
    justify-content: center;
}

.section-bf3-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    max-width: 1200px;
    width: 90%;
}

.section-bf3-image img {
    width: 100%;
    max-width: 550px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
}

.section-bf3-text {
    color: #fff;
    padding-left: 30px;
    max-width: 600px;
}

.section-bf3-text h2 {
    font-size: 2rem;
    color: #ff4500;
    margin-bottom: 20px;
}

.section-bf3-text p {
    font-size: 1.1rem;
    line-height: 1.6;
    color: #bdbdbd;
}

.section-bf1-container {
    width: 100%;
    background-color: #2e2e2e;
    padding: 50px 0;
    display: flex;
    justify-content: center;
}

.section-bf1-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    max-width: 1200px;
    width: 90%;
}

.section-bf1-image img {
    width: 100%;
    max-width: 550px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
}

.section-bf1-text {
    color: #fff;
    padding-right: 30px;
    max-width: 600px;
    text-align: left;
}

.section-bf1-text h2 {
    font-size: 2rem;
    color: #ff4500;
    margin-bottom: 20px;
}

.section-bf1-text p {
    font-size: 1.1rem;
    line-height: 1.6;
    color: #bdbdbd;
}

@media screen and (max-width: 900px) {
    .section-bf3-content, .section-bf1-content {
        flex-direction: column;
    }

    .section-bf3-text, .section-bf1-text {
        padding: 0;
        text-align: center;
        margin-top: 20px;
    }

    .section-bf3-image img, .section-bf1-image img {
        max-width: 100%;
    }
}

@keyframes fadeInUp {
    0% {
        opacity: 0;
        transform: translateY(30px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.section-bf3-container,
.section-bf1-container {
    opacity: 0;
    transform: translateY(30px);
    transition: opacity 0.6s ease-out, transform 0.6s ease-out;
    will-change: opacity, transform;
}

.section-bf3-container.visible,
.section-bf1-container.visible {
    opacity: 1;
    transform: translateY(0);
}

.section-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    max-width: 1200px;
    width: 90%;
}

.section-bf3-image img,
.section-bf1-image img {
    width: 100%;
    max-width: 550px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
}

.section-bf3-text,
.section-bf1-text {
    color: #fff;
    max-width: 600px;
}

.section-bf3-text h2,
.section-bf1-text h2 {
    font-size: 2rem;
    color: #ff4500;
    margin-bottom: 20px;
}

.section-bf3-text p,
.section-bf1-text p {
    font-size: 1.1rem;
    line-height: 1.6;
    color: #bdbdbd;
}

@media screen and (max-width: 900px) {
    .section-content {
        flex-direction: column;
    }

    .section-bf3-text,
    .section-bf1-text {
        padding: 0;
        text-align: center;
        margin-top: 20px;
    }

    .section-bf3-image img,
    .section-bf1-image img {
        max-width: 100%;
    }
}

.motivational-header {
    font-size: 2.5rem; 
    font-weight: bold; 
    text-align: center; 
    margin-bottom: 20px; 
    color: #fff; 
  }

.motivational-section {
    background-color: #2e2e2e; 
    padding: 50px 0;
    text-align: center;
    display: flex; 
    justify-content: center;
    align-items: flex-start;
}

.motivational-container {
    display: flex;
    max-width: 1200px; 
    width: 100%;
    justify-content: space-between;
    align-items: flex-start;
    
    padding: 0 20px; 
}

.map-section {
    flex: 1 1; 
    margin-right: 50px; 
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.motivational-content {
    flex: 2 1; 
    max-width: 800px;
    margin: 0;
    padding: 20px;
    background: rgba(0, 0, 0, 0.6); 
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    text-align: left; 
}

.motivational-content p {
    font-size: 1.2rem;
    line-height: 1.6;
    margin-bottom: 20px;
}

.motivational-content .last-paragraph {
    font-size: 1.4rem;
    color: #ffa500; 
}

.motivational-content {
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.8s ease, transform 0.8s ease;
}

.motivational-section.in-view .motivational-content {
    opacity: 1;
    transform: translateY(0);
    text-align: center;
    color: aliceblue;
}

@media screen and (max-width: 768px) {
    .motivational-container {
        flex-direction: column; 
        align-items: center;
    }

    .map-section {
        margin-right: 0;
        margin-bottom: 30px; 
        align-items: center; 
    }

    .motivational-content {
        padding: 15px;
    }

    .motivational-content p {
        font-size: 1rem;
    }
}

.mini-map-container {
    position: relative;
    width: 100%;
    max-width: 800px;
    margin: 20px auto;
  }
  
  .mini-map-image {
    width: 100%;
    height: auto;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .map-point {
    position: absolute;
    width: 20px;
    height: 20px;
    background-color: rgba(255, 69, 0, 0.8);
    border: 2px solid #fff;
    border-radius: 50%;
    cursor: pointer;
    transition: transform 0.3s ease;
  }
  
  .map-point:hover {
    transform: scale(1.2);
  }
  
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    max-width: 400px;
  }
  
  .modal-content h2 {
    margin-bottom: 10px;
    color: #ff4500;
  }
  
  .modal-content p {
    margin-bottom: 20px;
  }
  
  .modal-content button {
    background-color: #ff4500;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .modal-content button:hover {
    background-color: #ff6347;
  }
  
.food-description-simple-container {
  display: flex;
  justify-content: center;
  padding: 20px;
}

.grid {
  display: grid;
  grid-gap: 15px;
  grid-template-columns: repeat(3, minmax(150px, 1fr));
  grid-template-rows: repeat(2, 200px);  /* Ajuste la taille des lignes selon tes besoins */
  width: 90vw;  /* Utilisation de 90% de la largeur de l'écran */
  max-width: 1200px;  /* Limite maximale pour éviter les débordements */
  font-family: 'Courier New', Courier, monospace;
}

.card-grid {
  position: relative;
  overflow: hidden;
  border-radius: 40px;  
  cursor: pointer;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.card-grid img {
  width: 100%;
  height: 100%;
  object-fit: cover;  
  transition: transform 0.5s ease;
  border-radius: inherit;  
}

.card-grid:hover img {
  transform: translateY(-100%); 
}

.description {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  font-size: 18px;
  opacity: 0;
  transition: opacity 0.5s ease;
  padding: 10px;
  text-align: center;
  border-radius: inherit;  
}

.card-grid:hover .description {
  opacity: 1;
}

.card-grid:nth-child(1) {
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 2;
}

.card-grid:nth-child(2) {
  grid-row-start: 1;
  grid-row-end: 3;
}

.card-grid:nth-child(3) {
  grid-row-start: 2;
  grid-row-end: 4;
}

.card-grid:nth-child(5) {
  grid-row-start: 3;
  grid-row-end: 5;
}

.compteurs-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 50px;
  padding: 20px;
  width: 90%;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  min-height: 210px;
}


.compteur-item {
  display: flex;
  flex-direction: column;
  align-items: center;
}


.circle {
  width: 180px;
  height: 180px;
  background-color: transparent;
  border-radius: 50%;
  position: relative;
  background: conic-gradient(#ff4500 0%, #ff4500 70%, #e0e0e0 70%);
  box-shadow: 0 4px 15px rgba(255, 69, 0, 0.4), 
              inset 0px 0px 20px rgba(255, 255, 255, 0.1); 
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 0.3s ease; 
}

.circle:hover {
  transform: scale(1.1); 
  box-shadow: 0 6px 25px rgba(255, 69, 0, 0.6); 
}

.circle-content {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
}

.circle-content h3 {
  font-size: 1.3rem;
  margin: 0;
  color: #fff;
}

.circle-content p {
  font-size: 2rem;
  margin: 0;
  font-weight: bold;
  color: #fff;
}

.circle::before {
  content: '';
  width: 100%;
  height: 100%;
  background-color: transparent;
  border-radius: 50%;
  position: absolute;
  border: 10px solid #2e2e2e;
  animation: fill-circle 3s ease forwards;
  background: conic-gradient(#ff4500 0%, #ff8c00 50%, #ffd700 100%); /* Dégradé fluide */
}

@keyframes fill-circle {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.circle::after {
  content: '';
  width: 100%;
  height: 100%;
  border-radius: 50%;
  position: absolute;
  animation: pulse 2s infinite ease-in-out;
}

@keyframes pulse {
  0%, 100% {
    box-shadow: 0 0 10px rgba(255, 69, 0, 0.3);
  }
  50% {
    box-shadow: 0 0 20px rgba(255, 69, 0, 0.6);
  }
}

@media screen and (max-width: 900px) {
  .compteurs-container {
    flex-direction: column;
  }

  .circle {
    width: 140px;
    height: 140px;
  }

  .circle-content h3 {
    font-size: 1.1rem;
  }

  .circle-content p {
    font-size: 1.6rem;
  }
}

.formulaire-inscription-container {
    padding: 60px 40px;
    text-align: center;
    color: white;
    border-radius: 10px;
    max-width: 800px;
    margin: 0 auto;
  }
  
  .formulaire-inscription-container h2 {
    font-size: 2.5rem;
    color: #ff4500;
    margin-bottom: 20px;
    text-transform: uppercase;
    letter-spacing: 1.5px;
  }
  
  .formulaire-inscription-container p {
    font-size: 1.2rem;
    color: #bdbdbd;
    margin-bottom: 40px;
    line-height: 1.6;
  }
  
  .form-group {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .input-large {
    width: 70%;
    padding: 15px;
    margin-bottom: 30px;
    border: none;
    border-radius: 5px;
    font-size: 1.1rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .input-small {
    width: 45%;
    padding: 15px;
    margin-bottom: 20px;
    margin-right: 10px;
    border: none;
    border-radius: 5px;
    font-size: 1.1rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .input-small:last-child {
    margin-right: 0;
  }
  
  .submit-button {
    background-color: #ff4500;
    color: white;
    padding: 15px 30px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1.2rem;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-top: 30px;
  }
  
  .submit-button:hover {
    background-color: #e03e00;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
  }
  
  .success-message {
    margin-top: 40px;
    color: #bdbdbd;
  }
  
  .success-message p {
    font-size: 1.3rem;
  }
  
  .success-message a {
    display: inline-block;
    background-color: #7289da;
    color: white;
    padding: 15px 30px;
    border-radius: 5px;
    text-decoration: none;
    margin-top: 15px;
  }
  
  .success-message a:hover {
    background-color: #5a6fb8;
  }
  
  @media screen and (max-width: 768px) {
    .input-large {
      width: 90%;
    }
  
    .form-group {
      flex-direction: column;
    }
  
    .input-small {
      width: 90%;
      margin-right: 0;
    }
  }
  
 @font-face {
    font-family: 'MyFont';
    src: url("/static/media/CAMPUS PERSONAL USE.8eba57ce.ttf");
  } 
  
  .image {
    width: 100%;
    height: auto;
  }
  
  .text-overlay {
    position: absolute;
    top: 40%;
    left: 20%;
    transform: translate(-50%, -50%);
    color: white;
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    border-radius: 30px;
    height: 200px;
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-decoration: none;
}
  

  @media (max-width: 768px) {
    .text-overlay {
      font-size: px; 
    }
  }
  
