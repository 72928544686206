footer {
  background-color: #1a1a1a;
  color: white;
  padding: 50px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  transition: background-color 0.3s ease-in-out;
}

footer:hover {
  background-color: #222222; 
}

.footer-container1 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 80vw;
  transition: transform 0.3s ease;
}

/* .footer-container1:hover {
  transform: translateY(-10px); 
} */

.footer-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 80%;
  gap: 10px;
  flex-wrap: wrap;
}

.footer-logo-image {
  width: 100px;
  height: auto;
  margin-right: 20px;
  margin-left: 10vw;
  transition: opacity 0.5s ease, transform 0.3s ease-in-out;
}

@media (max-width: 768px) {
  .footer-logo-image {
    opacity: 0;
    transform: scale(0.8);
    pointer-events: none; 
  }
}

.footer-about,
.footer-categories,
.footer-links {
  flex: 1;
  margin-bottom: 30px;
  transition: opacity 0.5s ease, transform 0.3s ease;
}

.footer-about,
.footer-categories,
.footer-links {
  opacity: 0;
  transform: translateY(20px);
  animation: fadeInUp 1s forwards;
}

.footer-about {
  animation-delay: 0.1s;
}

.footer-categories {
  animation-delay: 0.3s;
}

.footer-links {
  animation-delay: 0.5s;
}

@keyframes fadeInUp {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.footer-about h3,
.footer-categories h3,
.footer-links h3 {
  font-size: 1.2em;
  margin-bottom: 15px;
  color: #ff4500;
  transition: color 0.3s ease;
}

.footer-about h3:hover,
.footer-categories h3:hover,
.footer-links h3:hover {
  color: #ffa500;
}

.footer-about p,
.footer-categories ul,
.footer-links ul {
  font-size: 0.9em;
  line-height: 1.6;
  color: rgba(255, 255, 255, 0.8);
}

.footer-categories ul,
.footer-links ul {
  list-style: none;
  padding: 0;
}

.footer-categories ul li,
.footer-links ul li {
  margin-bottom: 10px;
}

.footer-links ul li a {
  color: rgba(255, 255, 255, 0.8);
  text-decoration: none;
  transition: color 0.3s ease;
}

.footer-links ul li a:hover {
  color: #ff4500;
}

hr {
  border: none;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  width: 80%;
  margin: 30px auto;
}

.footer-copyright {
  text-align: center;
  font-size: 0.8em;
  color: rgba(255, 255, 255, 0.7);
  width: 80%;
  margin: 0 auto;
  max-width: 1200px;
}

@media (max-width: 768px) {
  .footer-container {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  hr {
    width: 90%;
  }

  .footer-copyright {
    font-size: 0.7em;
  }
}
