 @font-face {
    font-family: 'MyFont';
    src: url('./police/CAMPUS PERSONAL USE.ttf') ;
  } 
  
  .image {
    width: 100%;
    height: auto;
  }
  
  .text-overlay {
    position: absolute;
    top: 40%;
    left: 20%;
    transform: translate(-50%, -50%);
    color: white;
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    border-radius: 30px;
    height: 200px;
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-decoration: none;
}
  

  @media (max-width: 768px) {
    .text-overlay {
      font-size: px; 
    }
  }
  