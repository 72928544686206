.food-description-simple-container {
  display: flex;
  justify-content: center;
  padding: 20px;
}

.grid {
  display: grid;
  grid-gap: 15px;
  grid-template-columns: repeat(3, minmax(150px, 1fr));
  grid-template-rows: repeat(2, 200px);  /* Ajuste la taille des lignes selon tes besoins */
  width: 90vw;  /* Utilisation de 90% de la largeur de l'écran */
  max-width: 1200px;  /* Limite maximale pour éviter les débordements */
  font-family: 'Courier New', Courier, monospace;
}

.card-grid {
  position: relative;
  overflow: hidden;
  border-radius: 40px;  
  cursor: pointer;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.card-grid img {
  width: 100%;
  height: 100%;
  object-fit: cover;  
  transition: transform 0.5s ease;
  border-radius: inherit;  
}

.card-grid:hover img {
  transform: translateY(-100%); 
}

.description {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  font-size: 18px;
  opacity: 0;
  transition: opacity 0.5s ease;
  padding: 10px;
  text-align: center;
  border-radius: inherit;  
}

.card-grid:hover .description {
  opacity: 1;
}

.card-grid:nth-child(1) {
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 2;
}

.card-grid:nth-child(2) {
  grid-row-start: 1;
  grid-row-end: 3;
}

.card-grid:nth-child(3) {
  grid-row-start: 2;
  grid-row-end: 4;
}

.card-grid:nth-child(5) {
  grid-row-start: 3;
  grid-row-end: 5;
}
