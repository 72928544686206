
 @font-face {
     font-family: 'TimerFont';
     src: url('./police/28 Days Later.ttf'); 
 }
  
  .timer {
    display: flex;
    justify-content: center;
    font-size: 1vw;
  }
  
  .time-unit {
    margin: 0 20px;
    text-align: center;
  }
  
  .value {
    font-size: 1.5vw;
    font-family: TimerFont;
  }
  
  .label {
    font-size: 1.2em;
    color: white;
    font-family: TimerFont;
  }

#letter-timer {
    font-size: 1.6vw;
    margin-bottom: 10px;
  }
  